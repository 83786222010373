import React,{useEffect} from "react";

export default function RightSide(props) {

  const {setborderThickness,borderThickness,setedgeDesign , seteffect} = props;
  // useEffect(() => {
   
  // }, [props.borderThickness])
  
  return (
    <>
      <strong> Price Calculater </strong>
      <hr />
      
      <strong>Size</strong>
      <br />
      <br />
      <b>Quality </b> <input type="text" className="mx-2 " placeholder="Ex :- 1" /> <br />
      <br />
      <strong> Border Thickness</strong>
      <br />
      <div className="d-flex gap-5">
      {/* 1 inch */}
        <div className="shadow   bg-body rounded" onClick={e => props.setborderThickness(25)} >
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1885_1-inch91.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />{" "}
        </div>
  {/* **** 1.5 inch */}
        <div className="shadow   bg-body rounded" onClick={e => props.setborderThickness(30)}>
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1886_2-inch14.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />
        </div>
      </div>
      <span className="mx-2"> 1 inch</span>{" "}
      <span className="mx-5"> 2 inch</span>
      <br />
      <strong>Edge Design</strong>
      <br />
      <div className="d-flex gap-3">
      {/* *** folded */}
        <div className="shadow   bg-body rounded"  onClick={ e  => setedgeDesign("folded")}>
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1887_edgeFinalWrap.jpg"
            alt="inch 1"
            height={60}  
            width={60}
          />{" "} 
        </div>
{/*   mirror */}
        <div className="shadow   bg-body rounded">
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1888_edgeFinalMirror.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />
        </div>

        {/* black */}
        <div className="shadow   bg-body rounded" onClick={e =>         setedgeDesign("black")}>
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1889_edgeFinalBlack.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />
        </div>

        {/* white */}
        <div className="shadow   bg-body rounded" onClick={e =>         setedgeDesign("white")} >
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1890_edgeFinalWhite.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />
        </div>
      </div>
      <span className="ms-2"> Folded</span>{" "}
      <span className="mx-4"> Mirror</span> <span className="mx-3"> Black</span>{" "}
      <span className="ms-1"> White</span>
      <br />
      <strong>Image Effects</strong>
      <br />
      <div className="d-flex gap-3">
        <div className="shadow   bg-body rounded">
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1896_noneFrame58.jpg"
            alt="inch 1"
            height={60}
            width={60}
            onClick={() => seteffect("#E6E6E6")}
          />{" "}
        </div>

        <div className="shadow   bg-body rounded">
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1892_effectsBW.jpg"
            alt="inch 1"
            height={60}
            width={60}
            onClick={() => seteffect("#a1a1a1")}
          />
        </div>
        <div className="shadow   bg-body rounded">
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1893_sephia.jpg"
            alt="inch 1"
            height={60}
            width={60}
            onClick={() => seteffect("#ffeacb")}
          />
        </div>
      </div>
      <span className="ms-1" onClick={() => seteffect("#E6E6E6")}> Original</span>{" "}
      <span className="ms-3" onClick={() => seteffect("#a1a1a1")}> B & W</span> <span className="ms-4" onClick={() => seteffect("#ffeacb")}> Sepia</span>
      <br />
      <br />
      <strong>Frame Type</strong>
      <br />
      <div className="d-flex gap-3">
        <div className="shadow   bg-body rounded">
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1891_effectsOriginal28.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />{" "}
        </div>

        <div className="shadow   bg-body rounded">
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1894_Black-frame.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />
        </div>
        <div className="shadow   bg-body rounded">
          <img
            src="https://d1v713m6bal5um.cloudfront.net/b2cforprintshop/images/product_icon/1895_Dark-Brown-frame79.jpg"
            alt="inch 1"
            height={60}
            width={60}
          />
        </div>
      </div>
      <span className="ms-3 "> None</span>{" "}
      <span className="ms-4">Color</span> <span className="ms-4"> Premium</span>
    </>
  );
}
