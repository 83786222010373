import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";
import "./../style/leftSide.css";
export default function LeftSide(props) {
  // cannvas Height & Width

  const canvasHeight = 400;
  const canvasWidth = 400;

  // state

  const canvas = useRef(null);
  console.log("canvas", canvas);

  const [scale, setscale] = useState(2);

  const [base64Image, setbase64Image] = useState();

  const [modalBodyImage, setmodalBodyImage] = useState(
    "https://images.unsplash.com/photo-1587985064135-0366536eab42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODR8fGJlZHJvb20lMjBpbnRlcmlvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
  );

  const data = [
    "https://b2c.forprintshop.com/common_images/canvas_room1.jpg.webp",
    "https://b2c.forprintshop.com/common_images/canvas_room1.jpg.webp",
    "https://b2c.forprintshop.com/common_images/canvas_room2.jpg.webp",
    "https://images.unsplash.com/photo-1618220924273-338d82d6b886?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGJlZHJvb20lMjBpbnRlcmlvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1616486029423-aaa4789e8c9a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YmVkcm9vbSUyMGludGVyaW9yfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    "https://images.unsplash.com/photo-1609234841642-6008b93ab310?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGJlZHJvb20lMjBpbnRlcmlvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
  
    // for handling preview image 
    
 ];

  //  ************ props handling
  const { borderThickness, edgeDesign ,effect } = props;

  

  const [file, setfile] = useState("");

  // ********** canvas
  canvas.current = new fabric.StaticCanvas(
    "canvas"
    // {
    //   // left: 55,
    //   // top: 70,
    //   width: 500,
    //   height: 500,
    //   fill: "green",
    //   stroke: "black",
    //   strokeWidth: 50,
    // }
  );

  // ********* Add Image
  var imgURL = file;

  // ***************

  // ******************  HandleFile

  const handleFile = (e) => {
    setfile(URL.createObjectURL(e.target.files[0]));
  };

  // *************   with single canvas
  //  used rect for edges

  var rect1 = new fabric.Rect({
    left: 0,
    top: 0,
    fill: edgeDesign == "folded" ? "rgba(155,255,255, 0.5)" : edgeDesign,
    width: canvas.current.width,
    height: borderThickness,
    selectable: false,
  });
  var rect2 = new fabric.Rect({
    left: 0,
    top: 0,
    fill: edgeDesign == "folded" ? "rgba(155,255,255, 0.5)" : edgeDesign,
    width: borderThickness,
    height: canvas.current?.height,
    selectable: false,
  });
  var rect3 = new fabric.Rect({
    left: 0,
    top: canvas.current.height - borderThickness,
    fill: edgeDesign == "folded" ? "rgba(155,255,255, 0.5)" : edgeDesign,
    width: canvas.current.width,
    height: borderThickness,
    selectable: false,
  });
  var rect4 = new fabric.Rect({
    left: canvas.current.width - borderThickness,
    top: 0,
    fill: edgeDesign == "folded" ? "rgba(155,255,255, 0.5)" : edgeDesign,
    width: borderThickness,
    height: canvas.current.height,
    selectable: false,
  });

  // Add the rectangle object to the canvas
  canvas.current.add(rect1, rect2, rect3, rect4);
  canvas.current.bringToFront(rect1, rect2, rect3, rect4);
  // rect1.bringToFront();
  canvas.current.renderAll();

  // *********** add Image
  // useEffect(() => {
    // ********************* Add Image #1

    // fabric.Image.fromURL(
    //   imgURL,
    //   // file,
    //   //"https://images.unsplash.com/photo-1677184915745-03e070e63a0c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzMnx8fGVufDB8fHx8&auto=format&fit=crop&w=500&h=400&q=60",
    //   function (img) {
    //     // img.selectable=true
    //     img.set({
    //       scaleX: scale,
    //       // scaleY:scale
    //     });

    //     // **********  for change Image color start
    //     img.onload = function () {
    //       //      // Create the BlendColor filter
    //       var filter = new fabric.Image.filters.BlendColor({
    //         // color: '#94938E' // Set the color to blend with (red in this case)
    //         color: "#FF0000", // Set the color to blend with (red in this case)
    //       });

    //       // Apply the filter to the image
    //       img.filters.push(filter);

    //       // Apply the filters
    //       img.applyFilters();
    //       // Do something after the image has loaded
    //     };

    //     // **********  for change Image color end

    //     canvas.current.add(img);
    //     canvas.current.sendToBack(img);
    //     console.log("base64Image", base64Image);
    //     canvas.current.renderAll();
    //     setbase64Image(canvas.current.toDataURL("image/jpeg"));

    //     // }
    //   },
    //   { crossOrigin: "anonymous" }
    // );

    // *********************** #### 2
   
   
    var pugImg = new Image();
    pugImg.onload = function (img) {
      var pug = new fabric.Image(pugImg, {
        angle: 0,
        // width: canvas.current.width,
        // height: canvas.current.height,
        left: 0,
        top: 0,
        fill: "green",
        scaleX: 0,
        scaleY: 0,
        selectable:true
      });

      pug.scaleToWidth(canvas.current.width);
      // pug.scaleToHeight(canvas.current.height);
      canvas.current.add(pug);
            canvas.current.sendToBack(pug);
            console.log("base64Image",base64Image);
// ******** change color start

      var filter = new fabric.Image.filters.BlendColor({
            // color: '#FD88FC' // Set the color to blend with (red in this case)
            // color: '#CCC6CC' // Set the color to blend with (red in this case)
            // color: '#704214' // Set the color to blend with (red in this case)
            // color: "#FF0000", // Set the color to blend with (red in this case)
            color: effect, // Set the color to blend with (red in this case)
          });

          // Apply the filter to the image
          pug.filters.push(filter);

          // Apply the filters
          pug.applyFilters();
    //       // Do something after the image has loaded
// ******** change color End


            canvas.current.renderAll(); 
             setbase64Image(canvas.current.toDataURL("image/jpeg"))
         
  
    };
    console.log("Effect from Left side",effect);
    pugImg.src = file;

    useEffect(() => {
      setbase64Image(canvas.current.toDataURL("image/jpeg"))

    }, [file,scale, edgeDesign, borderThickness]);
  

  
   

  return (
    <>
      {/* previous */}
      {/* ******** input file */}
      {/* <input className='form-control' type="file"   onChange={handleFile}  /> */}

      {/* **********    canvas */}
      {/* <canvas id='canvas' height={500} width={500} style={{border:` ${borderWidth}px solid ${edgeDesign}`}} ></canvas>
         <canvas id='canvas'  ></canvas> */}

      {/* new */}

      <div class="card text-center">
        <div class="card-header">
          <div className="d-flex justify-content-between">
            <div className="btn btn-secondary w-25">
              <input
                className="form-control"
                type="file"
                // onChange={e => setfile(URL.createObjectURL(e.target.files[0]))}
                onChange={handleFile}
              />
            </div>
            <div className="btn btn-secondary w-25">My Files</div>
          </div>
        </div>
        <div class="card-body d-flex justify-content-center">
          <canvas
            // ref={ca}
            id="canvas"
            height={canvasHeight}
            width={canvasWidth}
            style={{ border: "2px solid black" }}
          ></canvas>
        </div>
        <div class="card-footer text-muted"> 
          {/* range */}
          <div className="border p-2">
            Print Quality :{" "}
            <input
              type="range"
              className="custom-range w-75"
              onChange={(e) => setscale(e.target.value)}
              min="0"
              max="5"
              step="0.2"
              defaultValue={1}
            />
            <span className="ms-2" onClick={(e) => setscale(0)}>
              Reset
            </span>
          </div>

          {/*  preview */}
          <br />
          <div className="row">
            {data.map((item, index) => (
              <div
                className="col-2 gap-1 p-1"
                key={index}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={(e) => setmodalBodyImage(item)}
              >
                <div id="wrapper" className="">
                  <img className="img-fluid " id="one" src={item} alt="" />
                  <img
                    className="img-fluid "
                    id="two"
                    src={base64Image}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* ***********  Modal Window */}

      <div
        className="modal "
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title" id="exampleModalLabel">
                Preview
              </p>
              <button
                type="button"
                className="btn-close btn-sm"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <div id="modalInner" className="shadow p-1 mb-5 bg-body rounded">
                <img className="img-fluid " src={modalBodyImage} alt="" />
                <img
                  // id="modalInsideImg"
                  className="img-fluid"
                  src={base64Image}
                  alt=""
                  style={{position:"absolute" , width : 150 , height : 100 , top : 50 , left : 100}}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                {data.map((item, index) => (
                  <div className="col-2 gap-1 p-1" key={index}>
                    {/* <img
                      className="img-fluid "
                      src={item}
                      alt=""
                      onClick={e => setmodalBodyImage(item)}
                    /> */}
                    {/* {console.log(index , "item",item)} */}

                    <div
                      id="wrapper"
                      className=""
                      onClick={(e) => setmodalBodyImage(item)}
                    >
                      <img className="img-fluid over " id="one" src={item} alt="" />
                      <img
                        className="img-fluid "
                        id="two"
                        src={base64Image}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
