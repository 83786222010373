import React, { useRef, useState, useEffect } from "react";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
export default function MainSection() {
  // const canvas = useRef();

  // canvas.current = new fabric.StaticCanvas("canvas");

  // state

  const [borderThickness, setborderThickness] = useState(20);

  const [edgeDesign, setedgeDesign] = useState("rgba(155,255,255, 0.5)");
  const [effect, seteffect] = useState("#E6E6E6")


  return (
    <>
      <div className="container mt-5">
        <h1 className="text-center my-5 ">Canvas</h1>
        <div className="row">
          <div className="col-sm-6">
            <LeftSide
              borderThickness={borderThickness}
              edgeDesign={edgeDesign}
              effect={effect}
            />
          </div>
          <div className="col-sm-6">
            <RightSide
              setborderThickness={setborderThickness}
              borderThickness={borderThickness}
              setedgeDesign={setedgeDesign}
              seteffect={seteffect}

              // canvas={canvas?.current}
            />
          </div>
        </div>
      </div>
    </>
  );
}
