import React from 'react'
import MainSection from './module/MainSection'

export default function App() {
  return (
    <div>
    
    <MainSection/>
    </div>
  )
}
